import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required!"),
  password: yup.string(),
  accessCode: yup.string(),
});

const sc_schema = yup.object().shape({
  email: yup.string().email().required("电子邮件为必填项！"),
  password: yup.string().required('密码是必需的！'),
  accessCode: yup.string(),
  
  
});
const ms_schema = yup.object().shape({
  email: yup.string().email().required("E-mel Diperlukan!"),
  password: yup.string().required('Kata laluan diperlukan!'),
  accessCode: yup.string(),
  
  
});
const tc_schema = yup.object().shape({
  email: yup.string().email().required("Email is Required!"),
  password: yup.string().required('密碼是必需的！'),
  accessCode: yup.string(),
  
  
});

export {
  schema,
  sc_schema,
  ms_schema,
  tc_schema
};
