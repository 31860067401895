import { Box, Button, CircularProgress, Grid, Link, makeStyles, Paper, Typography, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import { connect } from "react-redux";
import {schema,sc_schema,ms_schema,tc_schema} from "../../validations/login.schema";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { login } from "../../slices/authentication";
import { ROLE } from "../../constants/constants";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { convertFromHTML } from 'draft-js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { setLoginRole } from '../../slices/preference';
import { sendForgetPasswordEmail } from '../../slices/recruiter';
import SwipeableViews from 'react-swipeable-views'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TitleDialog from '../dialog/title-dialog/TitleDialog'
import Backdrop from '@material-ui/core/Backdrop';
import { getBrowserName, isRightBrowser } from '../../utils/browserUtils'
import config from '../../config'



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box px={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



// Style
const useStyles = makeStyles(theme => ({
    paperContainer: {
        backgroundImage: `url(${"/images/Website_Wallpaper_no_logo.png"})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            backgroundImage: `url(${"/images/Website_Wallpaper_no_logo_ipad.png"})`,
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${"/images/AIA-full-white-bg.png"})`,
        },
    },
    agreement: {
        display: 'block',
        flexDirection: 'rows',
        maxHeight: theme.spacing(91),
        maxWidth: theme.spacing(56.5),
        padding: theme.spacing(2)
    },
    formElement: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    forgotPassword: {
        margin: theme.spacing(-0.5, 0, 0, 0),
        float: 'right'
    },
    rememberMe: {
        margin: theme.spacing(-1.5, 0, -1.5, -1)
    },
    buttonSubmit: {
        minHeight: theme.spacing(6),
        // textTransform: 'none'
    },
    form: {
        marginTop: theme.spacing(1), // Fix IE 11 issue.
        width: '100%'
    },
    iconImage: {
        height: theme.spacing(16),
        // width: theme.spacing(14),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: theme.spacing(68),
        maxWidth: theme.spacing(56.5),
        padding: theme.spacing(4, 4, 4, 4)
    },
    welcomeBack: {
        marginBottom: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    textField: {
        color: theme.palette.common.black
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    error: {
        color: 'red',
        paddingTop: 8
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function createMarkup(msg) { return { __html: msg }; };

const AutoSubmitToken = ({ username, rememberMe, validationCode }) => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext();
    React.useEffect(() => {
        if (rememberMe && validationCode && username) {
            submitForm();
        }
    }, [rememberMe, username]);
    return null;
};

const Login = ({ count, dispatch, loginRole, validationCode, rememberMe, username, history, sendingForgetPasswordEmailInProgress,lang }, context) => {
    const classes = useStyles();

    const [requesting, setRequesting] = React.useState(false);
    const [showTerms, setShowTerms] = React.useState(false);
    const [showPrivacy, setShowPrivacy] = React.useState(false);
    const [showBrowser, setShowBrowser] = React.useState(false);
    const [rightBrowser, setRightBrowser] = React.useState(false);
    const formInitialState = {
        email: rememberMe ? username : '',
        password: '',
        accessCode: '',
        rememberMe: rememberMe,
    };

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [titleDialogTitle, setTitleDialogTitle] = React.useState(false);
    const [titleDialogOpen, setTitleDialogOpen] = React.useState(false);

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const showTermsAndCondition = (e) => {
        setShowTerms(true);
        e.preventDefault();
    };
    const closeTermsAndCondition = (e) => {
        setShowTerms(false);
    };
    const showPrivacyPolicy = (e) => {
        setShowPrivacy(true);
        e.preventDefault();
    }
    const closePrivacyPolicy = (e) => {
        setShowPrivacy(false);
        e.preventDefault()
    }

    const showBrowserType = (e) => {
        setShowBrowser(true);
        e.preventDefault();
    };
    const closeBrowserType = (e) => {
        setShowBrowser(false);
    };

    const handleRoleChange = value => {
        setLoginRole(value);
    };

    handleRoleChange(ROLE.recruiter)

    const callLogin = (values, actions) => {
        actions.setStatus({ success: null });
        let password = '';
        password = values.password;
        setRequesting(true)
        dispatch(login(values.email, password, ROLE.recruiter, values.rememberMe, validationCode)).then((returnObj) => {
            actions.setSubmitting(false);
            if (returnObj.token) {
                const prepath = history.location.state?.prePath
                if (prepath) history.push(prepath)
                else history.push('/recruiter/settings');
            } else {
                setRequesting(false)
                actions.setStatus({ success: '* ' + returnObj.message })
                // actions.resetForm()
            }

        })

    };

    const submit = (values, actions) => {
        actions.setStatus({ success: null });
        dispatch(sendForgetPasswordEmail(values.email)).then((errorCode) => {
            actions.setSubmitting(false);
            if (errorCode === 0) {
                setValue(0)
                setTitleDialogTitle(context.t("Forget_Password_Success_Message").replace('{email}', values.email));
                setTimeout(() => {
                    setTitleDialogOpen(true);
                }, 300);
            } else if (errorCode === 1) {
                setTitleDialogTitle(context.t("Forget_Password_Email_Not_Found_Message"));
                setTimeout(() => {
                    setTitleDialogOpen(true);
                }, 300);
            }
        })
    }

    useEffect(() => {
        window.addEventListener("load", function () {
            const browserName = getBrowserName()
            if( isRightBrowser( null, browserName ) ) {
              setRightBrowser( true )
            } else {
              setShowBrowser( true )
            }
        });
        console.log(loginRole)
    });
    const handleschema = (lang) =>{
        switch(lang) {
            case 'tc':
              return tc_schema;
            case 'sc':
              return sc_schema;
            case 'ms':
              return ms_schema;
            default:
              return schema;
          }
    }

    return (
        <Paper style={{height: '100vh', overflow: 'hidden', background: '#eeecef'}}>
            <Backdrop className={classes.backdrop} open={sendingForgetPasswordEmailInProgress}>
                <CircularProgress />
            </Backdrop>
            <TitleDialog
                message={titleDialogTitle}
                open={titleDialogOpen}
                handleClose={() => setTitleDialogOpen(false)}
            />
            <Grid container className={classes.paperContainer}>
                <Grid item style={{display: 'flex', gap: '2rem'}}>
                    <img className={classes.iconImage} src={config.verticalLogo} alt={"logo"} style={{height: '64px'}} />
                    <img className={classes.iconImage} src={config.verticalLogo2} style={{height: '64px'}} />
                </Grid>
                <Grid item>
                    <Paper className={classes.paper}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            containerStyle={{
                                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                            }}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Typography className={classes.welcomeBack} children={context.t('Login_HiEmployer')}
                                    variant={'body1'} />
                                {history.location.state?.prePath?
                                    <Typography className={classes.welcomeBack} children={context.t('Sap_videolink_welcome_message')}
                                    variant={'body1'} />:
                                <>
                                <Typography className={classes.welcomeBack} children={context.t('Login_WelcomeBack')}
                                    variant={'body1'} />
                                <Typography className={classes.title} children={context.t('Login_EnterYourDetailsBelow')}
                                    variant={'h5'} />
                                </>
                                }
                                <Formik initialValues={formInitialState} onSubmit={callLogin} validationSchema={handleschema(lang)}>
                                    {({ isSubmitting, status }) => (
                                        <Form>
                                            <Field name="email" label={context.t('Login_Email')} type="email" component={TextField}
                                                variant="filled" className={classes.formElement} fullWidth />
                                            <Field name="password" label={context.t('Login_Password')} type="password"
                                                component={TextField} variant="filled" className={classes.formElement}
                                                fullWidth />
                                            <Typography>
                                                <Button onClick={() => setValue(1)} className={classes.forgotPassword}>
                                                    {context.t("Login_ForgotPassword")}
                                                </Button>
                                            </Typography>
                                            <FormControlLabel label={context.t('Login_RememberMe')}
                                                className={classes.rememberMe} control={
                                                    <Field label="Remember Me" name="rememberMe" component={Checkbox} />
                                                }
                                            />
                                            <Box display="flex" justifyContent="center" mt={2} width={1}>
                                                {!requesting ? (
                                                    <Button variant="contained" type="submit" color="secondary"
                                                        className={classes.buttonSubmit} fullWidth>
                                                        {context.t('Login_Login')}
                                                    </Button>
                                                ) : (
                                                        <CircularProgress color="primary" />
                                                    )}
                                            </Box>
                                            <div className={classes.error}>{status ? status.success : ''}</div>
                                            <AutoSubmitToken rememberMe={rememberMe} username={username} validationCode={validationCode} />
                                        </Form>
                                    )}
                                </Formik>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Box mb={4}>
                                    <Button onClick={() => setValue(0)}>
                                        <ArrowBackIcon /> {context.t('Recruiter_Layout_Goback')}
                                    </Button>
                                </Box>
                                <Box px={3}>
                                    <Typography className={classes.welcomeBack} children={context.t('Forget_Password_Title')}
                                        variant={'h5'} />
                                    <Typography className={classes.title} children={context.t("Forget_Password_Subtitle")}
                                        variant={'body1'} />
                                    <Formik initialValues={{ email: '' }} onSubmit={submit} validationSchema={handleschema(lang)}>
                                        {({ isSubmitting, status }) => (
                                            <Form>
                                                <Field name="email" label={context.t('Login_Email')} type="email" component={TextField}
                                                    variant="filled" className={classes.formElement} fullWidth />
                                                <Box display="flex" justifyContent="center" width={1}>
                                                    {!requesting ? (
                                                        <Button variant="contained" type="submit" color="secondary" fullWidth>
                                                            {context.t("Recruiter_Layout_Submit")}
                                                        </Button>
                                                    ) : (
                                                            <CircularProgress color="primary" />
                                                        )}
                                                </Box>
                                                <div className={classes.error}>{status ? status.success : ''}</div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </TabPanel>
                        </SwipeableViews>

                    </Paper>
                </Grid>

                <Grid item className={classes.agreement} display={'flex'} style={{ textAlign: 'center' }}>
                    <Typography dangerouslySetInnerHTML={createMarkup(context.t('Login_Terms_Prefix'))} display={'inline'} component={'span'} />
                    {/* <Link color="primary"
                    href={'/docs/privacy'}
                     variant={'body1'} onClick={showPrivacyPolicy}>{context.t('Login_Privacy_Policy')}</Link> */}
                    <a style={{ whiteSpace: "nowrap", fontSize: '16px' }} color="primary" href='https://www.aia.com.my/en/index/privacy-statement.html' target="_blank" rel="noopener noreferrer" variant={'body1'}>{context.t('Login_Privacy_Policy')}</a>
                    <Typography display={'inline'} component={'span'}>{` ${context.t("And")} `}</Typography>
                    {/* <Link color='primary' 
                    // href={'/docs/privacy'}
                    href={'/docs/termsConditions'}
                    variant={'body1'} onClick={showTermsAndCondition}>{context.t('Login_Terms_Conditions')}</Link> */}
                    <a style={{ whiteSpace: "nowrap", fontSize: '16px' }} color='primary' href='https://www.aia.com.my/en/index/terms-of-use.html' target="_blank" rel="noopener noreferrer" variant={'body1'}>{context.t('Login_Terms_Conditions')}</a>
                    <Typography dangerouslySetInnerHTML={createMarkup(context.t('Login_Terms_Suffix'))} display={'inline'} component={'span'} />
                </Grid>
                <Dialog
                    open={showTerms}
                    onClose={closeTermsAndCondition}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{context.t('Login_Terms_Conditions')}</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                            dangerouslySetInnerHTML={createMarkup(context.t('Login_Terms_Conditions_Content').join('\n'))}
                        >
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeTermsAndCondition} color="primary">
                            {context.t('Login_Terms_Conditions_Close')}
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={showPrivacy}
                    onClose={closePrivacyPolicy}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{context.t('Login_Privacy_Policy')}</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                            dangerouslySetInnerHTML={createMarkup(context.t('Login_Privacy_Policy_Content').join('\n'))}
                        >
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePrivacyPolicy} color="primary">
                            {context.t('Login_Privacy_Policy_Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <Dialog
                    open={showBrowser}
                    onClose={closeBrowserType}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <p> Oops, your browser does not support video interviews. </p> <p>Please use the powerful Google Chrome browser. You can download it from:</p>
                            <p><a href="https://www.google.com/chrome/">https://www.google.com/chrome/</a></p>
                            <p> Or, you can use the Chrome browser in an Android mobile phone. Thanks! </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeBrowserType} color="primary">
                            {context.t('Login_Terms_Conditions_Close')}
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Paper >
    );
};

const mapState = state => {
    return {
        loginRole: state.preference.loginRole,
        validationCode: state.authenticated.validationCode,
        rememberMe: state.authenticated.rememberMe,
        username: state.authenticated.username,
        sendingForgetPasswordEmailInProgress: state.recruiter.sendingForgetPasswordEmailInProgress,
        lang: state.preference.language
    }
};


Login.contextTypes = {
    t: PropTypes.func,
};


export default withRouter(connect(mapState, null)(Login));


