import {
  createMuiTheme
} from '@material-ui/core/styles';
import {
  common,
  white
} from '@material-ui/core/colors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const defaultColor = '#022d41';
const secondaryColor = '#0957D9';
const blackColor = '#000000';
const selfThemeColor = "#D31145";

export default function NeufastTheme(primaryColor) {
  const color = primaryColor ? primaryColor : defaultColor;
  const breakpoints = createBreakpoints({})
  const smDown = useMediaQuery(breakpoints.down('sm'))
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
        secondary: selfThemeColor
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: smDown ? blackColor : defaultColor,
        secondary: "#0957D9",

      }
    },
    typography: {
      body2: {
        fontSize: '0.875rem',
      },
      body3: {
        fontSize: 6,
      },
      caption: {
        fontSize: 16,
        fontFamily: "AIAEverestMedium",
        letterSpacing: 0,
        fontWeight: "500"
      }
    },
    overrides: {
      MuiFilledInput: {
        underline: {
          // '&:before': {
          //   borderBottom: 0,
          // },
          '&:after': {
            borderBottomColor: color
          }
        }
      },
      MuiFormLabel: {
        root: {
          color: color,
          "&$focused": {
            color: color
          }
        }
      },
      MuiCheckbox: {
        root: {
          color: color
        }
      },
      MuiButton: {
        contained: {
          backgroundColor: 'white'
        },
        containedPrimary: {
          // color: '#FFFFFF',
          [breakpoints.down('sm')]: {
            borderRadius: 10,
            fontFamily: 'Helvetica',
            textTransform: 'none',
            boxShadow: '6px 6px 20px #3CA7D6',
            fontSize: 14,
            fontWeight: 'bold'
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          color: '#0B5ADA'
        },
        active: {
          color: '#0B5ADA !important'
        },
        icon: {
          color: '#333 !important'
        }
      },
      MuiTablePagination: {
        caption: {
          color: '#000 !important'
        }
      },
      // MuiSelect: {
      //   root: {
      //     color: '#000 !important'
      //   }
      // },
      MuiTableCell: {
        root: {
          padding: 16,
        },
        head: {
          color: '#000'
        }
      },
    },
  });
  return theme;
}