import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from "prop-types";

// Style
const useStyles = makeStyles(theme => ({
  footer: {
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "30px",
    width: "100%",
    padding: theme.spacing(1),
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  linkage: {
    textDecoration: 'none',
  },
  copyright: {
    color: theme.palette.primary.contrastText,
  },
  version: {
    position: 'absolute',
  }
}));

// Private Child Component
// eslint-disable-next-line no-empty-pattern
const Copyright = ({ }, context) => {
  const classes = useStyles();
  return (
    // <a className={classes.linkage} href="https://www.neufast.com/" target="_blank">
    //   <Typography variant="body2" align="center" className={classes.copyright}>
    //     {context.t('Footer_Copyright')}
    //   </Typography>
    // </a>
    <></>
  );
};

Copyright.contextTypes = {
  t: PropTypes.func
};

// Main Component
// eslint-disable-next-line no-empty-pattern
const Footer = ({ }, context) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        {/* <Box display='flex'> */}
          <Copyright />
          {/* <Typography variant="body2" align="right">test</Typography> */}
        {/* </Box> */}
      </Container>

    </footer>
  );
};

Footer.contextTypes = {
  t: PropTypes.func
};

export default Footer;
