import {
    createSlice
} from 'redux-starter-kit'
import {
    fetchSetting,
    setLayout,
    setEmail,
    setQuestion,
    setmultiQuestion,
    setWeighting,
    uploadQuestionFile,
    getCustomizedEvaluationApi,
    setCustomizedEvaluationApi,
    getCustomizedEvaluationByGuestApi,
    setConsent,
    getConsent,
    createMagicLinkData,
    fetchMagicLinkData,
} from '../apis/recruiter'
import {
    getQuestions
} from '../apis/questions'
import {
    getFullModelSubQuestionType,
    getQuestionSubType, getSimplifiedModelSubQuestionType
} from "../utils/programmeUtils";
import map from 'lodash/fp/map'
import defaultsDeep from 'lodash/fp/defaultsDeep'
import config from '../config'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import {
    sections,
    categories,
} from '../constants/competencies'

import { gets3object } from '../apis/s3access'
import { BUCKETS, VideoInterviewModel } from '../constants/constants';
import { nextQuestion } from './interview';

/**
 - programmeInEdit:
 {
       jobId: '',
       name: '',
     }
 - layout:
 {
      companyLogo: `/api/jobseeker/get_logo/${job_id}`,
      companyVideo: `/api/jobseeker/get_video/${job_id}`,
      companyName: "Neufast",
      interviewLanguage: "english",
      startDate: "2019-11-08 10:00:00",
      endDate: "2019-11-08 10:00:00",
      avator: "yes",
      color: "red",
      questions: [
        {
          "question_set": 1,
          "questions": [
            "Tell me about yourself",
            "Descibe your current or most recent job role"
          ],
          "sub_type": "Introduction",
          "type": "General HR & Employment"
        },
        {
          "question_set": 2,
          "questions": [
            "Descibe your current or most recent job role"
          ],
          "sub_type": "Introduction",
          "type": "General HR & Employment"
        }
      ],
      timezone = "UTC",
    }
 - email: {
      logo: "";
      domain: "";
      invitation: JSON.parse(response.invitation) || null;
      confirmation: JSON.parse(response.invitation) || null;
      reminder: JSON.parse(response.invitation) || null;
    }
 - scoring: {
      weighting: "weighting": [
        {
          "name": "enthusiasm_weight",
          "score": 10
        },
        {
          "name": "self_confidence_weight",
          "score": 10
        },
        {
          "name": "composure_weight",
          "score": 15
        },
        {
          "name": "pace_weight",
          "score": 10
        },
        {
          "name": "leadership_weight",
          "score": 5
        },
        {
          "name": "learning_agility_weight",
          "score": 5
        },
        {
          "name": "creativity_and_innovation_weight",
          "score": 5
        },
        {
          "name": "emotional_intelligence_weight",
          "score": 5
        },
        {
          "name": "communication_weight",
          "score": 10
        },
        {
          "name": "interpersonal_relationship_weight",
          "score": 10
        },
        {
          "name": "goal_and_achievement_striving_weight",
          "score": 15
        }
      ]
    }
 - questionOptions:
 [
 {
            "FormationMethod": 1,
            "Industry": "ALL",
            "JobFunction": "ALL",
            "Level": [
                0,
                2
            ],
            "Question_category": 116,
            "Question_text": "If you could be any animal, which would it be and why?",
            "Situation": [
                "ALL"
            ],
            "Skills": [
                3,
                4,
                5
            ],
            "_id": {
                "$oid": "5d5e0c95da09178c6bde19ae"
            },
            "soft_skill_1": "Communication and Relationship Building",
            "soft_skill_2": "Communication and Relationship Building",
            "soft_skill_3": "Strategy and Innovation"
        },
 ]
 */

let initialState = {
    jobId: null,
    completeCandidate: 0,
    layout: {
        companyName: "",
        avatar: false,
        colorScheme: '#022D41',
        interviewLanguage: get(config.interviewLanguages, '[0]', "english"),
        interviewLanguageVariant: '',
        timezone: 'UTC+00:00',
        companyMessage: '',
        questionLength: 2,
        preparationTime: 0,
        interviewer: {},
        layoutType: '1',
        consentEnabled: false,
        consentContent: "",
        candidate_upload_cv_required: 0
    },
    email: {
        emailDomain: "no-reply",
        showInvitation: false,
        showReminder: false,
        showConfirmation: false,
        showRedo: false,
        showReport: 0,
        showAdvancedSettings: false,
    },
    // flatten all categories in sections and map them to dictionary
    scoring: reduce([].concat(...map(section => {
        return map(category => `${category.toLowerCase()}_weight`)(categories[section])
    })(sections)), (acc, x) => Object.assign(acc, {
        [x]: 0
    }), {}),
    scoringMapping: map(section => ({
        name: `Recruiter_Scoring_${section.replaceAll('_', '')}`,
        area: map(category => ({
            label: `Recruiter_Skills_${category.replaceAll('_', '')}`,
            value: `${category.toLowerCase()}_weight`,
            definition: `Recruiter_Scoring_${category.replaceAll('_', '')}_Definition`,
        }))(categories[section]),
    }))(sections),
    questionOptions: [],
    customizedEvaluation: [],
    getProgrammeSettingInProgress: false,
    saveProgrammeSettingInProgress: false,
    getQuestionOptionInProgress: false,
    gettingCustomizedEvaluation: false,
    settingCustomizedEvaluation: false,
    onUpdatingFile: false,
    associatedAction: 'edit',
    completion: 0,
    model_type: null,
    // temporaryInterviewLayoutLanguage: 
};

const programmeSettingSlice = createSlice({
    slice: 'programmeSetting',
    initialState: initialState,
    reducers: {
        onEditAndLoadProgramme(state, action) {
            state.jobId = action.payload.jobId;
            state.getProgrammeSettingInProgress = true;
            state.onUpdatingFile = true
            if (action.payload.cachedJobId != state.jobId) {
                state.layout = {
                    companyName: "",
                    avatar: false,
                    colorScheme: '#022D41',
                    timezone: 'UTC+00:00',
                    interviewLanguage: get(config.interviewLanguages, '[0]', "english"),
                    interviewLanguageVariant: '',
                    interviewer: {},
                    questionLength: 2,
                    preparationTime: 0
                };
                state.email = {
                    emailDomain: "no-reply",
                    showInvitation: false,
                    showReminder: false,
                    showConfirmation: false,
                    showRedo: false,
                    showReport: 0,
                    showAdvancedSettings: false,
                };
                state.scoring = reduce([].concat(...map(section => {
                    return map(category => `${category.toLowerCase()}_weight`)(categories[section])
                })(sections)), (acc, x) => Object.assign(acc, {
                    [x]: 0
                }), {})
                state.customizedEvaluation = []
            }

        },
        onEditAndLoadProgrammeSuccess(state, action) {
            state.getProgrammeSettingInProgress = false;
            state.questionOptions = action.payload.questionOptions;
            state.layout = action.payload.layout;
            state.email = action.payload.email;
            state.scoring = action.payload.scoring;
            state.completeCandidate = action.payload.completeCandidate;
            state.interviewModel = action.payload.interviewModel;
        },
        onEditAndLoadProgrammeFailed(state, action) {
            state.getProgrammeSettingInProgress = false;
        },
        onSaveProgramme(state, action) {
            state.saveProgrammeSettingInProgress = true;
        },
        onSaveProgrammeLayoutSuccess(state, action) {
            state.saveProgrammeSettingInProgress = false;
            state.layout = action.payload;
            state.completion = 100
        },
        onSaveProgrammeQuestionSuccess(state, action) {
            state.saveProgrammeSettingInProgress = false;
            state.layout.multiQuestion = action.payload;
        },
        onSaveProgrammeEmailSuccess(state, action) {
            state.saveProgrammeSettingInProgress = false;
            // state.email = action.payload;
        },
        onSaveProgrammeScoringSuccess(state, action) {
            state.saveProgrammeSettingInProgress = false;
            state.scoring = defaultsDeep(getInitialWeighting())(convertScoringResult(get(action, 'payload', {})))
        },
        onSaveProgrammeFailed(state, action) {
            state.saveProgrammeSettingInProgress = false;
            state.completion = 100
        },
        setAssociatedAction(state, action) {
            state.associatedAction = action.payload
        },
        changeCompletion(state, action) {
            state.completion = action.payload
        },

        onGetCustomizedEvaluation(state, action) {
            state.gettingCustomizedEvaluation = true
        },
        onGetCustomizedEvaluationSuccess(state, action) {
            state.gettingCustomizedEvaluation = false
            state.customizedEvaluation = action.payload
        },
        onGetCustomizedEvaluationFailed(state, action) {
            state.gettingCustomizedEvaluation = false
            state.customizedEvaluation = []
        },

        onSetCustomizedEvaluation(state, action) {
            state.settingCustomizedEvaluation = true
        },
        onSetCustomizedEvaluationSuccess(state, action) {
            state.settingCustomizedEvaluation = false
        },
        onSetCustomizedEvaluationFailed(state, action) {
            state.settingCustomizedEvaluation = false
        },
        onUpdateFile(state, action){
            const {multiQuestionIndex, questionSetIndex, questionIndex, res} = action.payload
            state.layout.multiQuestion[multiQuestionIndex].question_sets[questionSetIndex].questions[questionIndex].file = res
        },
        onUpdateFileSuccess(state, action){
            state.onUpdatingFile = false
        }
    }
});

export const {
    onEditAndLoadProgramme,
    onEditAndLoadProgrammeSuccess,
    onEditAndLoadProgrammeFailed,
    onSaveProgramme,
    onSaveProgrammeFailed,
    onSaveProgrammeLayoutSuccess,
    onSaveProgrammeQuestionSuccess,
    onSaveProgrammeEmailSuccess,
    onSaveProgrammeScoringSuccess,
    setAssociatedAction,
    changeCompletion,
    onGetCustomizedEvaluation,
    onGetCustomizedEvaluationSuccess,
    onGetCustomizedEvaluationFailed,
    onSetCustomizedEvaluation,
    onSetCustomizedEvaluationSuccess,
    onSetCustomizedEvaluationFailed,
    onUpdateFile,
    onUpdateFileSuccess
} = programmeSettingSlice.actions;

export default programmeSettingSlice.reducer;


function getInitialWeighting() {
    return reduce([].concat(...map(section => {
        return map(category => `${category.toLowerCase()}_weight`)(categories[section])
    })(sections)), (acc, x) => Object.assign(acc, {
        [x]: 0
    }), {})
}

function convertScoringResult(weighting) {
    const result = {};
    // console.log(weighting)
    weighting.forEach((score) => {
        result[score.name] = score.score;
    });

    return result;
}

function generateQuestionOptions(softSkill, res, language) {

    if (res.ok) {
        let questions = [];
        if (res.obj && res.obj.length) {
            questions = res.obj.map((questionObj) => {
                return {
                    'english': questionObj.Question_text,
                    'mandarin': questionObj.Question_text_man,
                    'cantonese': questionObj.Question_text_can,
                    'thai': questionObj.Question_text_th,
                    'japanese': questionObj.Question_text_ja,
                    'indonesian': questionObj.Question_text_indo,
                    'malay': questionObj.Question_text_ms,
                    'vietnamese': questionObj.Question_text_vi,
                    'khmer': questionObj.Question_text_km,
                    "german": questionObj.Question_text_de,
                    "french": questionObj.Question_text_fr,
                    "korean": questionObj.Question_text_kr,
                    'full_competency1':questionObj.full_competency1,
                    'full_competency2':questionObj.full_competency2,
                    'simplified_competency':questionObj.simplified_competency,
                    "tagalog": questionObj.Question_text_ta,
                }
            });
        }
        return {
            softSkill,
            questions
        };
    } else return null;
}

function expandStructure(jsonText, context, actions) {
    if (jsonText) {
        try {
            const parsed = JSON.parse(jsonText);

            return {
                title: parsed.title,
                message: parsed.message,
                //attachment: parsed.attachment==1 ? true : false,
                //attachment_file: parsed.attachment ? parsed.attachment_file : null,
                addLogo: parsed.add_logo === 'yes' ? true : false,
                addSMS: (!!parsed.sms_msg && parsed.sms_msg != ''),
                SMSMsg: !!parsed.sms_msg ? parsed.sms_msg : actions ? context.t('Recruiter_SMS_' + actions + '_Default') : null,
                addComment: (!!parsed.comment && parsed.comment != ''),
                comment: !!parsed.comment ? parsed.comment : actions ? '' : null
            }
        } catch (e) {
            return newStructure();
        }
    } else {
        return null;
    }
}

function newStructure() {
    return {
        title: '',
        message: '',
        addLogo: false,
        attachment: true,
    }
}

export const defaultQuestion = {
    lang: 'english',
    type: '',
    sub_type: '',
    question: '',
    is_file: 0,
    file_name: "",
    openAudio: true,
    competency: []
}

export const fillQuestionSetsByDefault = (qsets, lang) => {
    const q = Object.assign({}, defaultQuestion, {
        lang
    })
    const defaultQuestionSet = {
        question_set: 1,
        questions: [q],
    }
    const fillQuestionSetByDefault = qset => {
        qset = defaultsDeep(defaultQuestionSet)(qset)
        qset.questions = map(defaultsDeep(q))(qset.questions)
        return qset
    }
    qsets = Array.isArray(qsets) && qsets.length > 0 ? qsets : [defaultQuestionSet]
    return map(fillQuestionSetByDefault)(qsets)
}

/**
 *
 * @param {String} job_id
 * @param {String} token
 */

export const startEditProgramSetting = (jobId, token, context) => async (dispatch, getState) => {
    const cachedJobId = getState().programmeSetting.jobId;
    const profile = getState().programme.profile;

    // if (cachedJobId !== jobId) {
    dispatch(onEditAndLoadProgramme({
        jobId: jobId,
        cachedJobId: cachedJobId
    }));
    try {
        const res = await fetchSetting(jobId, token);
        const softSkills = [];
        const questionOptions = [];
        // const allSubTypes = getQuestionSubType();

        if (profile.interviewModel === VideoInterviewModel.FULL) {

            const allSubTypes = getFullModelSubQuestionType()

            console.log(typeof allSubTypes, "-----type checking for all subtype")

            Object.keys(allSubTypes).forEach((key) => {
                allSubTypes[key].forEach((subtype) => {
                    // console.log(subtype.value, "-----subtype.value----")
                    softSkills.push(subtype.value);
                })
            });

        }
        if (profile.interviewModel === VideoInterviewModel.SIMPLIFIED) {
            const allSubTypes = getSimplifiedModelSubQuestionType()

            Object.keys(allSubTypes).forEach((key) => {
                allSubTypes[key].forEach((subtype) => {
                    // console.log(subtype.value, "-----subtype.value----")
                    softSkills.push(subtype.value);
                })
            });
        }
        if (!profile?.interviewModel){
            const allSubTypes = getQuestionSubType();

            Object.keys(allSubTypes).forEach((key) => {
                allSubTypes[key].forEach((subtype) => {
                    softSkills.push(subtype.value);
                })
            });
    
        }

        for (const softSkill of softSkills) {
            const questionOptionsRes = await getQuestions(softSkill, profile?.interviewModel || "default", token);
            // console.debug(`ProgrammeSetting.js: questionOptionsRes: `, questionOptionsRes.obj)
            const questionOption = generateQuestionOptions(softSkill, questionOptionsRes, getState().programmeSetting.layout.interviewLanguage);
            // console.debug(`ProgrammeSetting.js: questionOption: `, questionOption)

            if (questionOption) {
                questionOptions.push(questionOption);
            }

        }
        
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result;
            console.log("programmingSetting: ", result)
            const layout = {
                companyLogo: result && result.company_logo && result.company_logo != "null" ? await gets3object(result.company_logo, token, BUCKETS.MEDIA_BUCKET) : null,
                companyVideo: result && result.company_video && result.company_video != "null" ? await gets3object(result.company_video, token, BUCKETS.MEDIA_BUCKET) : null,
                companyName: result && result.company_name ? result.company_name : '',
                companyWebsite: result && result.company_website ? result.company_website : '',
                companyMessage: result && result.company_message ? result.company_message : '',
                companyMessage2: result && result.company_message2 ? result.company_message2 : '',
                companyMessage3: result && result.company_message3 ? result.company_message3 : '',
                interviewLanguage: get(result, 'interview_language', get(config.interviewLanguages, '[0]', 'english')),
                interviewLanguageVariant: get(result, 'interview_language_variant', ''),
                avatar: profile.colorBlindTest === 1 ? true : (result ? result.arator === 'yes' : false),
                colorScheme: result && result.colour_scheme ? result.colour_scheme : '',
                questions: result && result.qtype ? result.qtype : [{
                    question_set: 1,
                    questions: [{
                        file_name: "",
                        is_file: 0,
                        type: "",
                        sub_type: "",
                        question: "",
                        openAudio: true,
                        competency: []
                    }]
                }],
                candidate_upload_cv_required: result && "candidate_upload_cv_required" in result ? !!result.candidate_upload_cv_required : false,
                timezone: result ? result.timezone : 'UTC+08:00',
                timezoneArea: result ? result.timezone_area : null,
                questionLength: result && result.question_length ? result.question_length : 2,
                videoProcessing: result && result.video_processing ? result.video_processing : 0,
                preparationTime: result && result.preparation_time ? result.preparation_time : 0,
                interviewer: result && result.avatar_information ? result.avatar_information : {},
                layoutType: result && result.layout_type ? result.layout_type : '1',
                showAvatarIdentity: result && result.show_avatar_identity ? result.show_avatar_identity : false,
                interviewerImage: result && result.avatar_image ? await gets3object(result.avatar_image, token, BUCKETS.MEDIA_BUCKET) : null,
                jobId: jobId,

                sortQa: result & result.sort_qa ? result.sort_qa : null,
                mcq: result & result.mcq ? result.mcq : null,
                videoInterView: result & result.video_interview ? result.video_interview : null,
                interviewModel: result && (result.video_interview == 1 && result.video_interview_model) ? result.video_interview_model : null,
                testType: result && result.testtype ? result.testtype : ["Video Interview"],
                multiQuestion: result && result.multiQuestion ? result.multiQuestion : null,
                consentEnabled: result?.consentEnabled || false,
                qrCodeEnabled: result?.qrCodeEnabled || false,
            };

            // Consent Data
            if (layout.consentEnabled) {
                const consentRes = await getConsent(token, jobId)
                if (
                    consentRes.ok &&
                    consentRes.body.error_code === 0 &&
                    consentRes.body.status === "success"
                ) {
                    if ("consent_data" in consentRes.body.message) {
                        layout.consentContent = consentRes.body.message.consent_data.trim()
                    }
                }
            }
            if (!layout.consentContent) {
                layout.consentContent = ''
            }

            //QR Code Enabled
            if(layout.qrCodeEnabled){
                const magicLinkRes = await fetchMagicLinkData(token, jobId)
                if(
                    magicLinkRes.ok && 
                    magicLinkRes.body.error_code === 0
                ){
                    console.log("InterviewQRCode.jsx: tracking: temp:", magicLinkRes.body.msg)
                    let previousHashData = JSON.parse(magicLinkRes.body.msg)
                    if ("magic_url" in previousHashData){
                        console.log("url present")
                        layout.magicURL = previousHashData["magic_url"]
                    }
                    if ("deadline" in previousHashData){
                        console.log("deadline present")
                            layout.deadline = previousHashData["deadline"]
                        }
                    if ("email_verification" in previousHashData){
                        console.log("email verification present")
                        layout.qrCodeEmailVerification = previousHashData["email_verification"]
                    }
                }
            }

            //layout.questions = fillQuestionSetsByDefault(layout.questions.slice(), layout.interviewLanguage)

            // for (var questionSet of layout.questions) {
            //     for (var question of questionSet['questions']) {
            //         if (question['file'] === null) {
            //             question['file'] = '/images/mock-interview-3.jpg'
            //         }
            //     }
            // }
            if (layout.companyMessage === '') {
                if (layout.interviewLanguage === 'Candidate defined') {
                    layout.companyMessage = 'Thank you for taking part in the video interview. The result will be shared with your Introducer/Leader shortly.'
                    layout.companyMessage2 = 'Terima kasih kerana mengambil bahagian dalam temu bual video. Hasilnya akan dikongsi dengan Introducer/Leader anda tidak lama lagi.'
                    layout.companyMessage3 = '感谢您参加视频采访。 结果将很快与您的转介人/主管分享。'
                } else if (layout.interviewLanguage === 'mandarin') {
                    layout.companyMessage = '感谢您参加视频面試。 结果将很快与您的转介人/主管分享。'
                } else if (layout.interviewLanguage === 'malay') {
                    layout.companyMessage = 'Terima kasih kerana mengambil bahagian dalam temu bual video. Hasilnya akan dikongsi dengan Introducer/Leader anda tidak lama lagi.'
                } else if (layout.interviewLanguage === 'cantonese') {
                    layout.companyMessage = '感謝您參加視頻面試。結果將很快與您的介紹人/領導分享。'
                } else if (layout.interviewLanguage === 'thai') {
                    layout.companyMessage = 'ขอบคุณที่เข้าร่วมการสัมภาษณ์วิดีโอนี้ เราจะแจ้งผลให้คุณทราบในไม่ช้า'
                } else { // by default, english
                    if (profile.colorBlindTest === 1) {
                        layout.companyMessage = 'Thank you for taking part in this Color Blind Test. We will inform you the results shortly.'
                    } else {
                        layout.companyMessage = 'Thank you for taking part in the video interview. The result will be shared with your Introducer/Leader shortly.'
                    }
                }
            }

            const domain = {
                name: result && result.email_domain ? result.email_domain : 'no-reply',
                subdomain: result && result.email_subdomain ? result.email_subdomain : 'interview'
            }

            const email = {
                // emailLogo: result && result.email_logo ? `/api/recruiter/get_email_logo/${jobId}?${performance.now()}` : null,
                emailLogo: result && result.email_logo ? await gets3object(result.email_logo, token, BUCKETS.MEDIA_BUCKET) : null,

                emailDomain: domain,
                invitation: result ? expandStructure(result.invitation, context, 'Invitation') : null,
                confirmation: result ? expandStructure(result.confirmation, context, 'Confirmation') : null,
                reminder: result ? expandStructure(result.reminder, context, 'Reminder') : null,
                extension: result ? expandStructure(result.extension, context, 'Extension') : null,
                redo: result ? expandStructure(result.redo, context, 'Redo') : null,
                showReport: result ? expandStructure(result.report_send ? 1 : 0, context, 'showReport') : null,
                reportType: result ? expandStructure(result.pdf_report_type === "withscore" ? 1 : 0, context, 'reportType') : null,
                highScoreContent: result ? expandStructure(result?.high_score_content, context, 'HighScoreContent') : null,
                mediumScoreContent: result ? expandStructure(result?.medium_score_content, context, 'MediumScoreContent') : null,
                lowScoreContent: result ? expandStructure(result?.low_score_content, context, 'LowScoreContent') : null,
            };


            if (email.invitation) {
                email.invitation['attachment'] = result && result.attachment == 1 ? 1 : 0
                email.invitation['attachment_file'] = result && result.attachment_file ? result.attachment_file : null
            }

            email.showAdvancedSettings = result && result.show_advance_setting == 'true'
            email.showInvitation = !!email.invitation;
            email.showConfirmation = !!email.confirmation;
            email.showReminder = !!email.reminder;
            email.showExtension = !!email.extension;
            email.showRedo = !!email.redo
            email.showReport = result.report_send ? 1 : 0
            email.reportType = result.pdf_report_type === "withscore" ? 1 : 0

            var isold = ""
            if (layout.testType?.length == 1 && layout.testType[0] == 'Video Interview') isold = "_old" 
            if (!email.showInvitation) {
                email.invitation = {
                    title: layout.companyName ? layout.companyName.concat(' - ', context.t(`Recruiter_Email_Invitation_Title${isold}`)) : context.t(`Recruiter_Email_Invitation_Title${isold}`),
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t(`Recruiter_SMS_Invitation_Default${isold}`)
                };
            }
            if (!email.showReminder) {
                email.reminder = {
                    title: layout.companyName ? layout.companyName.concat(' - ', context.t(`Recruiter_Email_Reminder_Title${isold}`)) : context.t(`Recruiter_Email_Reminder_Title${isold}`),
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t(`Recruiter_SMS_Reminder_Default${isold}`)
                };
            }
            if (!email.showConfirmation) {
                email.confirmation = {
                    title: layout.companyName ? layout.companyName.concat(' - ', context.t(`Recruiter_Email_Confirmation_Title${isold}`)) : context.t(`Recruiter_Email_Confirmation_Title${isold}`),
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t(`Recruiter_SMS_Comfirmation_Default${isold}`)
                };
            }
            if (!email.showExtension) {
                email.extension = {
                    title: layout.companyName ? layout.companyName.concat(' - ', context.t(`Recruiter_Email_Extension_Title${isold}`)) : context.t(`Recruiter_Email_Extension_Title${isold}`),
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t(`Recruiter_SMS_Extension_Default${isold}`)
                };
            }
            if (!email.showRedo) {
                email.redo = {
                    title: layout.companyName ? layout.companyName.concat(' - ', context.t('Recruiter_Email_Redo_Title')) : context.t('Recruiter_Email_Redo_Title'),
                    addLogo: false,
                    addSMS: false,
                    //SMSMsg: context.t('Recruiter_SMS_Redo_Default')
                    SMSMsg: ''
                };
            }
            if (!email.showReport) {
                const title = layout.companyName ? layout.companyName.concat(' - ', context.t('Recruiter_Email_Report_Title')) : context.t('Recruiter_Email_Report_Title')
                email.lowScoreContent = {
                    title: title,
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
                email.mediumScoreContent = {
                    title: title,
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
                email.highScoreContent = {
                    title: title,
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
            }

            //when multiQuestion is null and there is no qtype
            if (layout.multiQuestion == null && !result.qtype) {
                const multiQuestion = []
                layout.testType.forEach((element) => {
                    if (element == "Video Interview"){
                        multiQuestion.push({
                            preparation: "",
                            test_duration: "",
                            test_name: "",
                            type: "Video Interview",
                            question_sets: [{
                                question_set: 1,
                                questions: [
                                    {
                                        file_name: "",
                                        is_file: "",
                                        lang: "english",
                                        openAudio: true,
                                        question: "",
                                        sub_type: "",
                                        type: "",
                                        competency:[],
                                    }
                                ]
                            }]
                        })
                    }else if (element == "Multiple Choices"){
                        multiQuestion.push({
                            test_name: "",
                            type: "Multiple Choices",
                            test_duration: "",
                            question_sets: [{
                                    question_set: 1,
                                    questions: [
                                        {
                                            ans_type: "Single Answer",
                                            file_name: "",
                                            is_file: 0,
                                            question: "",
                                            required: true,
                                            answers: [],
                                            choices: ["", ""],
                                        }
                                    ]
                            }]
                        })
                    }else if ( element == "Short Question"){
                        multiQuestion.push({
                            test_name: "",
                            type: "Short Question",
                            test_duration: "",
                            question_sets: [{
                                question_set: 1,
                                questions: [
                                    {
                                        answers:"",
                                        file_name:"",
                                        is_file: 0,
                                        question: "",
                                        required: true,
                                    }
                                ]
                            }]
                        })
                    }
                })
                layout.multiQuestion = multiQuestion
            }else if (layout.multiQuestion == null && result.qtype){      //If qtype exists
                const multiQuestion = [{
                    question_sets: result.qtype,
                    preparation: result && result.preparation_time ? result.preparation_time : 0,
                    test_name:"",
                    type: "Video Interview",
                    test_duration: result && result.question_length ? result.question_length : 2,
                    competency:[],
                }]
                layout.multiQuestion = multiQuestion
            }

            const scoring = defaultsDeep(getInitialWeighting())(convertScoringResult(get(result, 'weighting', [])))


            const bucketName = {
                "Video Interview": BUCKETS["RECRUITER_DATA"], "Multiple Choices": BUCKETS.MEDIA_BUCKET, "Short Question": BUCKETS.MEDIA_BUCKET
            }
        
            const pathName = {
                "Video Interview": "", "Multiple Choices": "mcq_media/", "Short Question": "short_question_media/"
            }

            const filePromise = []
            if(layout.multiQuestion){
                layout.multiQuestion.map((multiQuestion, multiQuestionIndex) => {
                    multiQuestion.question_sets.forEach((questionSet, questionSetIndex) => {
                        questionSet.questions.forEach((question, questionIndex) => {
                            if (!question.competency) 
                                question["competency"]=[]
                            if(question.filename && !(question.filename instanceof File)){
                                filePromise.push(gets3object(`${pathName[multiQuestion.type]}${question.filename}`, token, bucketName[multiQuestion.type])
                                    .then((res) => {
                                        dispatch(onUpdateFile({multiQuestionIndex, questionSetIndex, questionIndex, res}))
                                    })
                                )
                            }
                        })
                    })
                })
            }

            const payload = {
                layout,
                email,
                scoring,
                questionOptions,
                completeCandidate: result && result.num_of_complete_candidate ? result.num_of_complete_candidate : 0,
                interviewModel: result?.interviewModel || null,
            };
            dispatch(onEditAndLoadProgrammeSuccess(payload));
            Promise.all(filePromise)
            dispatch(onUpdateFileSuccess())
            return payload;
        } else {
            console.log("Error with api call (fetchSetting)");
            dispatch(onEditAndLoadProgrammeFailed());
        }
    } catch (err) {
        console.error(err, "-------------------");
        dispatch(onEditAndLoadProgrammeFailed({
            message: err.toString()
        }));
        return false;
    }
    // }
};

/**
 *
 * @param {File} company_logo
 * @param {File} company_video
 * @param {String} company_name
 * @param {String} company_message
 * @param {String} token
 * @param {String} interview_language
 * @param {String} timezone
 * @param {String} interview_start Please pass the stringtified date, e.g. "2019-11-08 10:00:00"
 * @param {String} interview_deadline Please pass the stringtified date, e.g. "2019-11-08 10:00:00"
 * @param {String} color
 * @param {String} job_id
 * @param {String} arator "yes", "no"
 * @param {[Object]} qtype will be stringtify in this function
 */
export const updateLayoutSetting = (token, jobId, layout, interviewModel) => async dispatch => {
    dispatch(onSaveProgramme());
    const onUploadProgress = (progressEvent) => {
        var completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // if (completed === 100) {
        //     completed = 99
        // }
        dispatch(changeCompletion(completed))
    }
    console.log(`InterviewQRCode.jsx: layout: `, layout) // still ok

    try {
        const res = await setLayout(token, jobId, layout, onUploadProgress, interviewModel);

        console.log(res);
        console.log(res.obj);

        if (res.ok && res.obj && res.obj.error_code === 0) {

            // Consent Form Field
            if (layout.consentEnabled) {
                const consentRes = await setConsent(token, jobId, layout.parsedConsentContent)
                if (!(consentRes.ok &&
                    consentRes.body.error_code === 0 && 
                    consentRes.body.status === 'success')) {
                     throw new Error("Unsuccessful! Please check the consent being uploaded")   
                } else {
                    console.log("sucessfully upload the consent")
                }
            }

            // Magic Link Field
            if(layout.qrCodeEnabled){
                let configs = {
                    magic_url: layout.magicURL,
                    deadline: layout.deadline,
                    email_verification: layout.qrCodeEmailVerification,
                }
                configs = JSON.stringify(configs)
                // const magicLinkRes = await createMagicLinkData(token, jobId, layout.magicURL, layout.deadline)
                const magicLinkRes = await createMagicLinkData(token, jobId, configs)
                if(!(magicLinkRes.ok && 
                    magicLinkRes.body.error_code === 0)) {
                        console.error(magicLinkRes.body.msg)
                        throw new Error(magicLinkRes.body.msg)
                    } else {
                        console.log("Successfully uploaded magic link data.")
                    }
            }


            const updatedLayout = {
                ...layout,
                videoProcessing: res.obj.message.video_processing,
                consentContent: layout.parsedConsentContent
            };
            delete updatedLayout.parsedConsentContent
            if (layout.companyLogo && layout.companyLogo !== "null") {
                let company_logo_name = `company_logos/companyLogo-${jobId}${layout.companyLogoExtension}`
                console.log(company_logo_name, "----------------------")
                updatedLayout.companyLogo = await gets3object(company_logo_name, token, BUCKETS.MEDIA_BUCKET)
            }
            if (layout.companyVideo && layout.companyVideo !== "null") {
                let company_video_name = `company_videos/companyVideo-${jobId}${layout.companyVideoExtension}`
                updatedLayout.companyVideo = await gets3object(company_video_name, token, BUCKETS.MEDIA_BUCKET)
            }
            if (layout.interviewerImage && layout.interviewerImage !== "null") {
                let interviewer_image_name = `interviewer_images/interviewerImage-${jobId}${layout.interviewerImageExtension}`
                updatedLayout.interviewerImage = await gets3object(interviewer_image_name, token, BUCKETS.MEDIA_BUCKET)
            }
            dispatch(onSaveProgrammeLayoutSuccess(updatedLayout));
            return true;
        } else {
            console.log(res)
            console.log("Error with api call (setQuestion)");
            dispatch(onSaveProgrammeFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onSaveProgrammeFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const updateQuestionSetting = (token, jobId, multiQuestion) => async dispatch => {
    dispatch(onSaveProgramme());
    try {
        //====================================================================================
        // for (var questionSet of questions) {
        //     for (var question of questionSet['questions']) {
        //         const res = await uploadQuestionFile(token, question.file, question.filename)
        //         if (question['file'] != null) {
        //             question['file'] = `/api/recruiter/get_question_file/${question.filename}`
        //         }
        //     }
        // }
        //------------------------------------------------------------------------------------
        for (var m_question of multiQuestion){
            for (var q_set of m_question['question_sets'] ){
                for (var question of q_set['questions']) {
                    if (question['file'] != null && typeof (question.file) != "string") {
                        // const res = await uploadQuestionFile(token, question.file, question.filename) not in use
                        question['file'] = question.filename ? await gets3object(`question_files/${question.filename}`, token, BUCKETS.MEDIA_BUCKET) : null
                    }
                }
            }
        }
        //===================================================================================
        const res = await setmultiQuestion(token, jobId, multiQuestion);
        if (res.ok && res.obj && res.obj.error_code === 0) {



            // const updatedLayout = {
            //     ...layout,
            //     companyLogo: `/api/jobseeker/get_logo/${jobId}?${performance.now()}`,
            //     companyVideo: `/api/jobseeker/get_video/${jobId}?${performance.now()}`,
            // };
            dispatch(onSaveProgrammeQuestionSuccess(multiQuestion));
            return true;
        } else {
            console.log("Error with api call (setmultiQuestion)");
            dispatch(onSaveProgrammeFailed());
            return false;
        }
    } catch (err) {
        console.log(err, "uplaod question error");
        dispatch(onSaveProgrammeFailed({
            message: err.toString()
        }));
        return false;
    }
};

/**
 *
 * @param {File} email_logo
 * @param {String} token
 * @param {String} email_domain
 * @param {Object} invitation will be stringtify in this function
 * @param {Object} reminder will be stringtify in this function
 * @param {Object} confirmation will be stringtify in this function
 * @param {String} job_id
 */
export const updateEmailSetting = (token, jobId, email, context) => async dispatch => {
    dispatch(onSaveProgramme());
    try {
        const res = await setEmail(token, jobId, email);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const payload = {
                emailLogo: `/api/recruiter/get_email_logo/${jobId}?${performance.now()}`,
                emailDomain: email.emailDomain,
                invitation: email.invitation,
                reminder: email.reminder,
                confirmation: email.confirmation,
                extension: email.extension,
                redo: email.redo,
                showReport: email?.showReport,
                reportType: email?.reportType,
                lowScoreContent: email.lowScoreContent,
                mediumScoreContent: email.mediumScoreContent,
                highScoreContent: email.highScoreContent,
                showAdvancedSettings: email.showAdvancedSettings
            };
            payload.showInvitation = !!payload.invitation;
            payload.showConfirmation = !!payload.confirmation;
            payload.showExtension = !!payload.extension;
            payload.showReminder = !!payload.reminder;
            payload.showRedo = !!payload.redo
            payload.showReport = !!payload.report_send

            if (payload.showInvitation) {
                payload.invitation.addSMS = !!payload.invitation.SMSMsg && payload.invitation.SMSMsg != ''

                if (payload.invitation.SMSMsg == '') {
                    payload.invitation.SMSMsg = context.t('Recruiter_SMS_Invitation_Default')
                }
            }
            if (payload.showReminder) {
                payload.reminder.addSMS = !!payload.reminder.SMSMsg && payload.reminder.SMSMsg != ''
                if (payload.reminder.SMSMsg == '') {
                    payload.reminder.SMSMsg = context.t('Recruiter_SMS_Reminder_Default')
                }
            }
            if (payload.showConfirmation) {
                payload.confirmation.addSMS = !!payload.confirmation.SMSMsg && payload.confirmation.SMSMsg != ''
                if (payload.confirmation.SMSMsg == '') {
                    payload.confirmation.SMSMsg = context.t('Recruiter_SMS_Confirmation_Default')
                }
            }
            if (payload.showExtension) {
                payload.extension.addSMS = !!payload.extension.SMSMsg && payload.extension.SMSMsg != ''
                if (payload.extension.SMSMsg == '') {
                    payload.extension.SMSMsg = context.t('Recruiter_SMS_Extension_Default')
                }
            }
            if (payload.showReport && payload.reportType) {
                payload.highScoreContent.addComment = !!payload.highScoreContent.comment && payload.highScoreContent.comment != ''
                payload.mediumScoreContent.addComment = !!payload.mediumScoreContent.comment && payload.mediumScoreContent.comment != ''
                payload.lowScoreContent.addComment = !!payload.lowScoreContent.comment && payload.lowScoreContent.comment != ''

                // if (payload.highScoreContent.comment == '') {
                //     payload.highScoreContent.comment = context.t('Recruiter_SMS_Extension_Default')
                // }
            }
            if (payload.showReport && !payload.reportType) {
                payload.highScoreContent.addComment = !!payload.highScoreContent.comment && payload.highScoreContent.comment != ''
                payload.highScoreContent.addComment = !!payload.highScoreContent.comment && payload.highScoreContent.comment != ''
                payload.highScoreContent.addComment = !!payload.highScoreContent.comment && payload.highScoreContent.comment != ''
                // if (payload.mediumScoreContent.comment == '') {
                //     payload.mediumScoreContent.comment = context.t('Recruiter_SMS_Extension_Default')
                // }
            }
            if (!payload.showInvitation) {
                payload.invitation = {
                    title: '',
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t('Recruiter_SMS_Invitation_Default')
                };
            }
            if (!payload.showReminder) {
                payload.reminder = {
                    title: '',
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t('Recruiter_SMS_Reminder_Default')
                };
            }
            if (!payload.showConfirmation) {
                payload.confirmation = {
                    title: '',
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t('Recruiter_SMS_Confirmation_Default')
                };
            }
            if (!payload.showExtension) {
                payload.extension = {
                    title: '',
                    addLogo: false,
                    addSMS: false,
                    SMSMsg: context.t('Recruiter_SMS_Extension_Default')
                };
            }
            if (!payload.showRedo) {
                payload.redo = {
                    title: '',
                    addLogo: false,
                    addSMS: false,
                    //SMSMsg: context.t('Recruiter_SMS_Redo_Default')
                    SMSMsg: ''
                };
            }
            if (!payload.showReport) {
                payload.low_score_content = {
                    title: '',
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
                payload.medium_score_content = {
                    title: '',
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
                payload.high_score_content = {
                    title: '',
                    addLogo: false,
                    addComment: false,
                    comment: ''
                };
            }
            dispatch(onSaveProgrammeEmailSuccess(payload));
            return true;
        } else {
            console.log("Error with api call (setEmail)");
            dispatch(onSaveProgrammeFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onSaveProgrammeFailed({
            message: err.toString()
        }));
        console.log(err.toString())
        return false;
    }
};

/**
 *
 * @param {String} job_id
 * @param {String} token
 * @param {Object} weighting
 */
export const updateScoreSetting = (job_id, token, weighting) => async dispatch => {
    dispatch(onSaveProgramme());
    try {
        const res = await setWeighting(job_id, token, weighting);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const scoring = {
                weighting
            };
            dispatch(onSaveProgrammeScoringSuccess(weighting));
            return true
        } else {
            console.log("Error with api call (setWeighting)")
            dispatch(onSaveProgrammeFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onSaveProgrammeFailed({
            message: err.toString()
        }));
        return false;
    }
};

/**
 *
 * @param {String} token
 * @param {String} jobId
 */
export const getCustomizedEvaluation = (token, jobId, context) => async dispatch => {
    let ret = false
    try {
        dispatch(onGetCustomizedEvaluation())
        const response = await getCustomizedEvaluationApi(token, jobId)
        // console.log(response, "res")
        if (response.ok && get(response, 'obj.error_code') === 0) {
            dispatch(onGetCustomizedEvaluationSuccess(get(response, 'obj.data', [])))
            ret = true
        } else if (response.ok && get(response, 'obj.error_code') === 1) {
            const evaluationWeighting = get(response, 'obj.data', []).map((category) => {
                return {
                    'name': context.t(category.name),
                    'possessions': category.possessions.map((weighting) => {
                        return {
                            'name': context.t(weighting.name),
                            'weight': weighting.weight,
                            'id': weighting.id
                        }
                    })
                }
            })
            dispatch(onGetCustomizedEvaluationSuccess(evaluationWeighting))
            ret = true
        } else {
            console.log('Error with api call (getCustomizedEvaluation)')
            dispatch(onGetCustomizedEvaluationFailed())
        }
    } catch (e) {
        console.log(e)
        dispatch(onGetCustomizedEvaluationFailed({
            message: e.toString()
        }))
    }
    return ret
}

/**
 *
 * @param {String} token
 */
export const getCustomizedEvaluationByGuest = (token, context) => async dispatch => {
    let ret = false
    try {
        dispatch(onGetCustomizedEvaluation())
        const response = await getCustomizedEvaluationByGuestApi(token)
        console.log(response)
        if (response.ok && get(response, 'obj.error_code') === 0) {
            dispatch(onGetCustomizedEvaluationSuccess(get(response, 'obj.data', [])))
            ret = true
        } else if (response.ok && get(response, 'obj.error_code') === 1) {
            const evaluationWeighting = get(response, 'obj.data', [])
            dispatch(onGetCustomizedEvaluationSuccess(evaluationWeighting))
            ret = true
        } else {
            console.log('Error with api call (getCustomizedEvaluation)')
            dispatch(onGetCustomizedEvaluationFailed())
        }
    } catch (e) {
        console.log(e)
        dispatch(onGetCustomizedEvaluationFailed({
            message: e.toString()
        }))
    }
    return ret
}

/**
 *
 * @param {String} token
 * @param {String} jobId
 */
export const setCustomizedEvaluation = (token, jobId, configuration) => async dispatch => {
    let ret = false
    try {
        dispatch(onSetCustomizedEvaluation())
        const response = await setCustomizedEvaluationApi(token, jobId, configuration)
        if (response.ok && get(response, 'obj.error_code') === 0) {
            dispatch(onSetCustomizedEvaluationSuccess())
            ret = true
        } else {
            console.log('Error with api call (getCustomizedEvaluation)')
            dispatch(onSetCustomizedEvaluationFailed())
        }
    } catch (e) {
        console.log(e)
        dispatch(onSetCustomizedEvaluationFailed({
            message: e.toString()
        }))
    }
    return ret
}