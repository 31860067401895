import clientRequest from "../constants/clientRequest"

const tag2 = 'api/voice';

//function mapLanguageToVoiceLang(language) {
//    if (language === 'en') {
//        return 'english';
//    } else if (language === 'tc') {
//        return 'cantonese';
//    } else {
//        return 'mandarin';
//    }
//}

export const triggerTextToSpeechAll = (questions, language, token) => {
    return clientRequest.then(client => {
        // Tags Interface
        //const hash = sha1(`${Question_text}${language}`);
        return client.apis[tag2].post_all_voice({
            payload: {
                questions,
                language,
                token
            }
        });
    });
};

export const triggerTextToSpeech = (Question_text, language, token) => {
    return clientRequest.then(client => {
        // Tags Interface
        //const hash = sha1(`${Question_text}${language}`);
        return client.apis[tag2].post_voice({
            payload: {
                //Question_id: hash,
                Question_text,
                token,
                //languge: mapLanguageToVoiceLang(language),
                language,
            }
        });
    });
};

const voice = {
    triggerTextToSpeech,
    triggerTextToSpeechAll
};

export default voice;