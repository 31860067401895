import Swagger from 'swagger-client'
import config from '../config'
import clientRequest from "../constants/clientRequest"

const tag = "api/session"


export const checkSessionToken = (interview_id, token) => {
    return clientRequest.then(client => {
        return client.apis[tag].post_check_session_token({
            payload: {
                identifier: interview_id,
                session_token: token,
            }
        })
    })
}


const session = {
    checkSessionToken
}

export default session