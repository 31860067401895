import moize from 'moize'
import clientRequest from "../constants/clientRequest"

const tag = 'api/questions';

const __getQuestions = (soft_skill, model, token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_fetch_questions({
                payload: {
                    soft_skill,
                    token,
                    model
                }
            });
        })
};
export const getQuestions = moize( __getQuestions, { maxAge: 5 * 60 * 1000 } )

export const fetchCandidateDefinedLangQuestion = (question, language, settingQuestionLang) => {
    return clientRequest.then((client) => {
        return client.apis[tag].post_fetch_candidate_defined_lang_question({
            payload: {
                question,
                language,
                settingQuestionLang
            }
        })
    })
}

export const getAvatarInfo = (payload) => {
    return clientRequest.then((client) => {
        return client.apis["api/avatar"].post_avatar({
            payload: payload
        })
    })
}

const questions = {
    getQuestions
};

export default questions;
