import { Message } from "@material-ui/icons";
import clientRequest from "../constants/clientRequest"

const tag = 'api/logging';

export const logPageUnload = (token, unloadType, message, currentStatus) => {
    return clientRequest.then(client => client.apis[tag].post_log_page_unload({
        payload: {
          token: token,
          unload_type: unloadType,
          message: message,
          status: currentStatus,
        }
      })
    )
}

export const logButtonClick = (token, buttonName, message, currentStatus) => {
    return clientRequest.then(client => client.apis[tag].post_log_button_click({
        payload: {
          token: token,
          button_name: buttonName,
          message: message,
          status: currentStatus,
        }
      })
    )
}

export const logVideoSubmission = (token, functionName, questionName, attemptNumber, message, currentStatus) => {
    return clientRequest.then(client => client.apis[tag].post_log_video_submission({
        payload: {
          token: token,
          function_name: functionName,
          question_num: questionName,
          attempt_number: attemptNumber,
          message: message,
          status: currentStatus,
        }
      })
    )
}