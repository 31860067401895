import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: 'white',
      boxShadow: 'none'
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    paddingBottom: 20
  },
  title: {
    marginBottom: 10,
    fontSize: 24,
    textAlign: 'center',
    whiteSpace: "pre-line"
  },
  buttonSet: {
    justifyContent: 'center',
    alignCenter: 'center',
    marginBottom: 20
  },
  button: {
    justifyContent: 'center',
    alignCenter: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 75,
    paddingRight: 75,
  },
  double_button: {
    justifyContent: 'center',
    alignCenter: 'center',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 55,
    paddingRight: 55,
    marginRight: 25,
    mraginLeft: 25
  }
}));

const TitleDialog = ({ message, open, handleClose, buttonNeed, handleYes }, context) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle onClose={handleClose}>
        &nbsp;
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant={"h3"} className={classes.title} children={message} />
        </Box>

      </DialogContent>
      <DialogActions className={classes.buttonSet}>
        {!buttonNeed &&
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleClose}
          >
            {context.t('Close')}
          </Button>}

        {buttonNeed &&
          (<div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.double_button}
              onClick={handleYes}
            >Send</Button>
            <Button
              variant="outlined"
              className={classes.double_button}
              onClick={handleClose}
            >No</Button>
          </div>)}

      </DialogActions>
    </Dialog>
  );
};

TitleDialog.contextTypes = {
  t: PropTypes.func,
};

export default TitleDialog;