import config from '../config'
import clamp from 'lodash/fp/clamp'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import find from 'lodash/find'
import get from 'lodash/get'

export const getProgrammeAndJobName = (availableProgrammes, jobId) => {
    for (const programme of availableProgrammes) {
        for (const job of programme.job_list) {
            if (job.job_id === jobId) {
                return [programme.program_name, job.job_name,];
            }
        }
    }
    return ['', ''];
};

export const getProgramme = (availableProgrammes, jobId) => {
    for (const programme of availableProgrammes) {
        for (const job of programme.job_list) {
            if (job.job_id === jobId) {
                return programme;
            }
        }
    }
    return {}
};

export const getQuestionType = () => {
    return [{
        key: "LayoutSettings_QuestionType_General",
        value: "General HR & Employment"
    },
    {
        key: "LayoutSettings_QuestionType_SoftSkills",
        value: "Competency Soft Skills"
    },
    ]
};



export const getSimplifiedModelSubQuestionType = () => {

    return {
        "General HR & Employment": [{
            key: "LayoutSettings_QuestionSubType_Intro",
            value: "Introduction"
        },
        {
            key: "LayoutSettings_QuestionSubType_Motivation",
            value: "Motivation & Career Plans"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strenth",
            value: "Strength, Achievements & Weakness"
        },
        {
            key: "LayoutSettings_QuestionSubType_Attitude",
            value: "Attitude, Interest & Value"
        },
        {
            key: "LayoutSettings_QuestionSubType_Understanding",
            value: "Role/Company Understanding"
        },
        {
            key: "LayoutSettings_QuestionSubType_WorkPlan",
            value: "Work Plan"
        },
        {
            key: "LayoutSettings_QuestionSubType_Salary",
            value: "Salary"
        },
        {
            key: "LayoutSettings_QuestionSubType_Others",
            value: "Others"
        },
        ],

        "Competency Soft Skills": [{
            key: "Recruiter_Skills_TaskPerformance",
            value: "Task Performance"
        },
        {
            key: "Recruiter_Skills_InterpersonalRelationshipCommunication",
            value: "Interpersonal relationship and Communication"
        },
        {
            key: "Recruiter_Skills_Adaptability",
            value: "Adaptability"
        },
        ]
    }
}

export const getFullModelSubQuestionType = () => {
    return {
        "General HR & Employment": [{
            key: "LayoutSettings_QuestionSubType_Intro",
            value: "Introduction"
        },
        {
            key: "LayoutSettings_QuestionSubType_Motivation",
            value: "Motivation & Career Plans"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strenth",
            value: "Strength, Achievements & Weakness"
        },
        {
            key: "LayoutSettings_QuestionSubType_Attitude",
            value: "Attitude, Interest & Value"
        },
        {
            key: "LayoutSettings_QuestionSubType_Understanding",
            value: "Role/Company Understanding"
        },
        {
            key: "LayoutSettings_QuestionSubType_WorkPlan",
            value: "Work Plan"
        },
        {
            key: "LayoutSettings_QuestionSubType_Salary",
            value: "Salary"
        },
        {
            key: "LayoutSettings_QuestionSubType_Others",
            value: "Others"
        },
        ],
        "Competency Soft Skills": [

            {
                key: "Recruiter_Skills_Leadership",
                value: "Leadership"
            },
            {
                key: "Recruiter_Skills_DevelopingPeople",
                value: "Developing People"
            },
            {
                key: "Recruiter_Skills_GoalAndAchievementStriving",
                value: "Goal and Achievement Striving"
            },
            {
                key: "Recruiter_Skills_Responsibility",
                value: "Responsibility"
            },
            {
                key: "Recruiter_Skills_ManagingExecution",
                value: "Managing Execution"
            },
            {
                key: "Recruiter_Skills_AnalyticalAndLogicalThinking",
                value: "Analytical and Logical Thinking"
            },
            {
                key: "Recruiter_Skills_ComplexProblemSolving",
                value: "Complex Problem Solving"
            },
            {
                key: "Recruiter_Skills_VisionAndEntrepreneurialMindset",
                value: "Vision and Entrepreneurial Mindset"
            },
            {
                key: "Recruiter_Skills_TechnologyExpertiseAndBusinessSense",
                value: "Technology Expertise and Business Sense"
            },
            {
                key: "Recruiter_Skills_Communication",
                value: "Communication with Impact"
            },
            {
                key: "Recruiter_Skills_CaringAndEmpathy",
                value: "Caring and Empathy"
            },
            {
                key: "Recruiter_Skills_InterpersonalRelationship",
                value: "Interpersonal relationship"
            },
            {
                key: "Recruiter_Skills_TeamCollaborationAndInclusion",
                value: "Team Collaboration and Inclusion"
            },
            {
                key: "Recruiter_Skills_BuildingRelationshipsAndNetwork",
                value: "Building Relationships and Network"
            },
            {
                key: "Recruiter_Skills_EffectiveCommunicationAndTeambuilding",
                value: "Effective Communication and Teambuilding"
            },
            {
                key: "Recruiter_Skills_FlexibilityAndAdaptability",
                value: "Flexibility and Adaptability"
            },
            {
                key: "Recruiter_Skills_LearningAgility",
                value: "Learning Agility"
            },
            {
                key: "Recruiter_Skills_CreativityAndInnovation",
                value: "Creativity and Innovation"
            },
            {
                key: "Recruiter_Skills_EmotionalIntelligence",
                value: "Emotional Intelligence"
            },
            {
                key: "Recruiter_Skills_GritAndPassion",
                value: "Grit and Passion"
            },
        ]
    }
}

export const getQuestionSubType = () => {
    return {
        "General HR & Employment": [{
            key: "LayoutSettings_QuestionSubType_Intro",
            value: "Introduction"
        },
        {
            key: "LayoutSettings_QuestionSubType_Motivation",
            value: "Motivation & Career Plans"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strenth",
            value: "Strength, Achievements & Weakness"
        },
        {
            key: "LayoutSettings_QuestionSubType_Attitude",
            value: "Attitude, Interest & Value"
        },
        {
            key: "LayoutSettings_QuestionSubType_Understanding",
            value: "Role/Company Understanding"
        },
        {
            key: "LayoutSettings_QuestionSubType_WorkPlan",
            value: "Work Plan"
        },
        {
            key: "LayoutSettings_QuestionSubType_Salary",
            value: "Salary"
        },
        {
            key: "LayoutSettings_QuestionSubType_Others",
            value: "Others"
        },
        ],
        "Competency Soft Skills": [{
            key: "LayoutSettings_QuestionSubType_Leadership",
            value: "Leadership and Management"
        },
        {
            key: "LayoutSettings_QuestionSubType_Teamwork",
            value: "Teamwork and Service Oriented"
        },
        {
            key: "LayoutSettings_QuestionSubType_Communication",
            value: "Communication and Relationship Building"
        },
        {
            key: "LayoutSettings_QuestionSubType_ProblemAnalysis",
            value: "Understanding and Problem Analysis"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strategy",
            value: "Strategy and Innovation"
        },
        {
            key: "LayoutSettings_QuestionSubType_Process",
            value: "Managing Process and Execution"
        },
        {
            key: "LayoutSettings_QuestionSubType_Change",
            value: "Change and Resilience"
        },
        {
            key: "LayoutSettings_QuestionSubType_Venturing",
            value: "Venturing and Achievement Oriented"
        },
        ]
    }
};

export const getColorSchemes = () => [{
    value: '#022D41',
    color: '#022D41'
}, {
    value: '#EF4044',
    color: '#EF4044'
}, {
    value: '#00A9BA',
    color: '#00A9BA'
}, {
    value: '#D5EDF3',
    color: '#D5EDF3'
}, {
    value: '#ACACAC',
    color: '#ACACAC'
}];

export const getLanguage = () => config.interviewLanguages.map(x => ({
    key: `InterviewLanguage_${mapLocale(x)}`,
    value: x,
}))


const languageShortForm = {
    "english": "en",
    "cantonese": "tc",
    "mandarin": "sc",
    "thai": "th",
    "japanese": "ja",
    "indonesian": "id",
    "malay": "ms",
    "vietnamese": "vi",
    "khmer": "km",
    "german": "de",
    "french": "fr",
    "korean": "kr",
    "tagalog": "ta",
}

const languageFull = {
    "en": "english",
    "tc": "cantonese",
    "sc": "mandarin",
    "th": "thai",
    "ja": "japanese",
    "id": "indonesian",
    "ms": "malay",
    "vi": "vietnamese",
    "km": "khmer",
    "de": "german",
    "fr": "french",
    "kr": "korean",
    "ta": "tagalog",
}

// TODO:
// since there's no mapping between spoken and written language, the two
// should be separately used and stored
//   e.g. hk: ( cantonese, traditional chinese ),
//        mainland china: ( mandarin, simplified chinese),
//        taiwan: ( mandarin, traditional chinese )
export const mapLocale = language => config.languages.length <= 0 ? 'en' :
    config.interviewLanguages.length <= 0 ? 'en' :
        config.interviewLanguages.indexOf(language) === -1 ? config.languages[0] : languageShortForm[language]

export const shortFormToFull = lang => languageFull[lang]

export const scoringOptions = [{
    option: 'Recruiter_Scoring_Irrelevant',
    weight: 0,
}, {
    option: 'Recruiter_Scoring_Bonus',
    weight: 1,
}, {
    option: 'Recruiter_Scoring_Required',
    weight: 2,
}, {
    option: 'Recruiter_Scoring_Critical',
    weight: 3,
}
]

export const mapPercentageToScoreLevel = score => isNumber(score) && !isNaN(score) ?
    clamp(0, 3)(Math.floor(score)) : 0

export const mapScoreLevelToPercentage = level => isNumber(level) && !isNaN(level) ?
    clamp(0, 100)(level * 33.3333) : 0

export const getWeightingText = (weighting) => {
    const scoreLevel = mapPercentageToScoreLevel(weighting)
    const option = find(scoringOptions, { weight: scoreLevel })
    return get(option, 'option', 'No relevant weighting')
}
