const config = {
  version: '1.0.1',
  baseUrl: '',
  languages: ['en', 'tc', 'sc', 'ms'],
  interviewLanguages: ['english', 'cantonese', 'mandarin', 'thai', 'japanese', 'indonesian', 'malay', 'vietnamese', 'khmer','german','french','korean','tagalog'],
  horizontalLogo: '/logo/AEA_Logo.png',
  horizontalLightLogo: '/logo/AEA_Logo.png',
  verticalLogo: '/logo/AEA_Logo.png',
  verticalLogo2: '/logo/AIA_HLBL_LogoLockup_PNG_red.png',
  tr_jobId: ["專業見習生_20221019104742", "專業實習生_20221019053917", "Management Trainee_20211210072629", "JC PROcruit C_20221008014118", "JC PROcruit C - Test_20221010101224", "JC PROcruit C_20221010114022", "JC PROcruit C_20221013080053"], //HKJC Test 4_20211210065420"
}

console.debug = () => {}
console.log = () => {}

export default config
